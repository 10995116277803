<template>
  <v-container class="is-md py-12">
    <v-row class="mb-6" align="end">
      <v-col cols="12" md="6">
        <h3 class="text-h6 font-weight-bold">
          Search boxes
        </h3>
        <p class="text-caption mb-0">
          View and edit boxes and their items
        </p>
      </v-col>

      <v-col cols="12" md="6" class="text-right">
        <v-btn
          light color="green--gradient"
          class="px-3 unwidth" to="/cases/create"
        >
          <v-icon class="text-h6">
            mdi-plus
          </v-icon>
          Create new
        </v-btn>
      </v-col>
    </v-row>

    <v-row align="end" class="mb-3">
      <v-col cols="12" md="2">
        <h4 class="text-caption lh-1 text-uppercase font-weight-bold">
          Market filter
        </h4>

        <v-chip-group
          v-model="filters.market"
          column color="primary500"
        >
          <v-chip
            v-for="market in markets" :key="market._id"
            label small
            :value="market._id"
            class="text-uppercase font-weight-medium text-caption"
          >
            {{ market.slug }}
          </v-chip>
        </v-chip-group>
      </v-col>

      <v-col cols="12" md="4" align-self="center">
        <v-checkbox v-model="filters.includeArchived" dense hide-details label="include archived" />
      </v-col>

      <v-col cols="12" md="6" class="d-flex align-center">
        <v-text-field
          v-model="filters.search" filled dense outlined
          prepend-inner-icon="mdi-magnify"
          class="mr-2"
          placeholder="id or name"
          hide-details
        />
        <v-btn
          light color="primary--gradient" :loading="loading.list"
          class="px-3 unwidth" @click="fetchApiData"
        >
          <v-icon class="text-h6">
            mdi-sync
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="data-table mb-8 mt-2"
      item-key="_id"
      :headers="headers"
      :items="entries"
      :options.sync="options"
      :footer-props="{ itemsPerPageOptions: [5, 10, 25] }"
      :server-items-length="total"
      :loading="loading.list || loading.filtering"
      @click:row="onEditItem"
    >
      <template v-if="loading.filtering" #loading>
        <div>
          Waiting for inputs to end...
        </div>
      </template>

      <template #item.name="{ item, value }">
        <div class="d-flex align-center">
          <v-chip
            v-if="item.market"
            label color="primary--gradient" light
            class="mr-2 px-2 font-weight-bold text-uppercase"
          >
            {{ item.market.slug }}
          </v-chip>
          <v-tooltip v-else top color="red">
            <template #activator="{ on }">
              <v-icon color="red" class="mr-2" v-on="on">
                mdi-alert
              </v-icon>
            </template>
            <span>Broken Market link! Fix ASAP!</span>
          </v-tooltip>

          <div class="lh-1-2">
            <h4 class="text-body-2 lh-1 font-weight-medium">
              <span v-text="value" />

              <v-icon
                v-if="item.type"
                color="primary" class="ml-1" small :title="item.type"
              >
                mdi-star
              </v-icon>
              <v-icon
                v-if="item.disabled"
                color="red" class="ml-1" small title="Disabled"
              >
                mdi-close-box
              </v-icon>
              <v-icon
                v-if="item.archived"
                color="red" class="ml-1" small title="Archived"
              >
                mdi-archive-remove
              </v-icon>
            </h4>
            <a
              :href="toBoxUrl(item)" target="_blank" class="lh-1-2 text-caption"
              @click.stop
              v-text="toBoxUrl(item, true)"
            />
          </div>
        </div>
      </template>

      <template #item.categories="{ value }">
        <span v-if="!value" class="text--disabled">&mdash;</span>
        <v-chip
          v-for="category in value" :key="category._id" label small
          class="mr-2"
        >
          {{ category.name }}
        </v-chip>
      </template>

      <template #item.edge="{ value }">
        <span v-if="!value" class="text--disabled">&mdash;</span>
        <span class="text--secondary">{{ value / 100 | toLocale }}%</span>
      </template>

      <template #item.price="{ value, item }">
        <v-icon v-if="!value" color="red" class="mr-2" v-on="on">
          mdi-alert
        </v-icon>

        <MarketPrice
          class="font-weight-semibold"
          :market="item.market" :value="item.price"
        />
      </template>

      <template #item.createdAt="{ value }">
        <v-tooltip top color="primary500">
          <template #activator="{on}">
            <span v-on="on">{{ value | relativeTime('twitter') }}</span>
          </template>
          <span>{{ value | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <div class="text-caption text-center">
      <span class="mx-3">
        <v-icon class="mr-1 text-h6" color="primary">
          mdi-star
        </v-icon>
        <span>Special box</span>
      </span>
      <span class="mx-3">
        <v-icon class="mr-1 text-h6" color="red">
          mdi-close-box
        </v-icon>
        <span>Disabled</span>
      </span>
      <span class="mx-3">
        <v-icon class="mr-1 text-h6" color="red">
          mdi-archive-remove
        </v-icon>
        <span>Archived</span>
      </span>
    </div>
  </v-container>
</template>

<script>
import { debounce } from '@/utils'
import { mapGetters } from 'vuex'
import MarketPrice from '@/components/global/MarketPrice.vue'

export default {
  components: { MarketPrice },
  metaInfo: {
    title: 'Boxes search',
  },
  data() {
    return {
      loading: {
        list: false,
      },
      entries: [],
      total: 0,
      filters: {
        search: '',
        market: null,
        includeArchived: null,
      },
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        itemsPerPage: 10,
        mustSort: true,
        page: 1,
      },
      headers: [
        { text: 'Name', value: 'name', align: 'start' },
        {
          text: 'Categories', value: 'categories', align: 'start', sortable: false,
        },
        { text: 'Edge', value: 'edge', sortable: false },
        { text: 'Price', value: 'price', align: 'end' },
        { text: 'Created at', value: 'createdAt', align: 'end' },
      ],
      markets: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
  },
  watch: {
    options: {
      handler() {
        if (!this.entries.length) this.fetchApiData()
        else {
          this.entries = []
          this.loading.filtering = true
          this.onOptionsChanged()
        }
      },
      deep: true,
    },
    filters: {
      handler() {
        this.options.page = 1
        this.entries = []
        this.loading.filtering = true
        this.onOptionsChanged()
      },
      deep: true,
    },
  },
  created() {
    this.onGetMarkets()
  },
  methods: {
    onOptionsChanged: debounce(function onOptionsChanged() {
      this.fetchApiData()
    }, 350),
    async fetchApiData() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options

      try {
        this.loading.filtering = false
        this.loading.list = true

        const { entries, total } = await this.$socket.request('admin.cases.get', {
          page,
          filters: this.filters,
          sortBy: sortBy[0],
          asc: !sortDesc[0],
          limit: itemsPerPage,
        })

        this.entries = entries
        this.total = total
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading.list = false
      }
    },
    async onGetMarkets() {
      try {
        this.loading.markets = true
        this.markets = await this.$socket.request('admin.markets.get')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.markets = false
      }
    },
    onEditItem(box) {
      this.$router.push({ name: 'Boxes edit', params: { id: box._id } })
    },
    toBoxUrl(box, slim = false) {
      if (slim) {
        return `/${box.market?.slug}/${box.slug}`
      }

      return `${CONFIG.appUrl}/${box.market?.slug}/${box.slug}`
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  tbody tr {
    cursor: pointer;
  }

  .v-data-footer {
    background-color: grey(500);
  }
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
